import React from 'react';

const GitlabSVG = () => {
	return (
		<svg viewBox="0 0 157 150" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="GitLab_logo" fillRule="nonzero">
					<path d="M154,59.37 L153.79,58.81 L132.59,3.51 C132.160901,2.42416946 131.397396,1.50305948 130.41,0.88 C128.916001,-0.0763893536 127.057217,-0.26837272 125.399281,0.362470855 C123.741346,0.993314429 122.480343,2.37237446 122,4.08 L107.69,47.89 L49.74,47.89 L35.43,4.08 C34.9459879,2.3750485 33.684775,0.999079359 32.0283118,0.368796092 C30.3718486,-0.261487175 28.5149626,-0.0719527762 27.02,0.88 C26.0326042,1.50305948 25.2690986,2.42416946 24.84,3.51 L3.65,58.82 L3.43,59.37 C-2.83731533,75.7495461 2.48833193,94.2995992 16.49,104.86 L16.57,104.92 L16.75,105.06 L49,129.23 L65,141.32 L74.72,148.67 C77.0633023,150.440064 80.2966977,150.440064 82.64,148.67 L92.36,141.32 L108.36,129.23 L140.84,104.92 L140.93,104.85 C154.930742,94.2936475 160.260115,75.7488987 154,59.37 L154,59.37 Z" id="Path" fill="#E24329"></path>
					<path d="M154,59.37 L153.79,58.81 C143.460916,60.9295669 133.728481,65.307455 125.29,71.63 L78.74,106.83 L108.38,129.23 L140.86,104.92 L140.95,104.85 C154.943483,94.2882743 160.264417,75.7445136 154,59.37 L154,59.37 Z" id="Path" fill="#FC6D26"></path>
					<path d="M49,129.23 L65,141.32 L74.72,148.67 C77.0633023,150.440064 80.2966977,150.440064 82.64,148.67 L92.36,141.32 L108.36,129.23 L78.72,106.83 L49,129.23 Z" id="Path" fill="#FCA326"></path>
					<path d="M32.14,71.63 C23.7045761,65.3106331 13.9753581,60.9360707 3.65,58.82 L3.43,59.37 C-2.83731533,75.7495461 2.48833193,94.2995992 16.49,104.86 L16.57,104.92 L16.75,105.06 L49,129.23 L78.66,106.83 L32.14,71.63 Z" id="Path" fill="#FC6D26"></path>
				</g>
			</g>
		</svg>
	);
};

export default GitlabSVG;