import React from 'react';

const Error404Page = () => {
	return (
		<div>
			Sorry, not a page!
		</div>
	);
};

export default Error404Page;