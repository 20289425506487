import React from 'react';

const AngularSVG = () => {
	return (
		<svg width="188px" height="200px" viewBox="0 0 188 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Angular_full_color_logo" fillRule="nonzero">
					<polygon id="Path" fill="#DD0031" points="94 0 94 0 94 0 0.9 33.2 15.1 156.3 94 200 94 200 94 200 172.9 156.3 187.1 33.2"></polygon>
					<polygon id="Path" fill="#C3002F" points="94 0 94 22.2 94 22.1 94 123.4 94 123.4 94 200 94 200 172.9 156.3 187.1 33.2"></polygon>
					<path d="M94,22.1 L35.8,152.6 L35.8,152.6 L57.5,152.6 L57.5,152.6 L69.2,123.4 L118.6,123.4 L130.3,152.6 L130.3,152.6 L152,152.6 L152,152.6 L94,22.1 L94,22.1 L94,22.1 L94,22.1 L94,22.1 Z M111,105.4 L77,105.4 L94,64.5 L111,105.4 Z" id="Shape" fill="#FFFFFF"></path>
				</g>
			</g>
		</svg>
	);
};

export default AngularSVG;

