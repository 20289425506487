import { atom } from 'recoil';

export const screenBelowLg = atom({
	key: 'screenBelowLg',
	default: false
});

export const screenBelowSm = atom({
	key: 'screenBelowSm',
	default: false
});