import React from 'react';

const GcpSVG = () => {
	return (
		<svg viewBox="0 0 432 384" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Google-cloud-platform" fillRule="nonzero">
					<path d="M282.4,76.9 L238.3,0.1 L122.6,0.1 C112.3,0.1 102.7,5.6 97.7,14.6 L4.1,177.7 C-1,186.6 -1,197.4 4.1,206.4 L39.7,268.5 L83.7,192.1 L149.9,76.9 L282.4,76.9 Z" id="path22" fill="#F8BB16"></path>
					<path d="M428.2,177.7 L334.6,14.6 C329.4,5.7 319.9,0.1 309.7,0.1 L238.3,0.1 L282.4,76.9 L348.6,192.1 L282.4,307.3 L370.4,307.3 L428.2,206.4 C433.2,197.5 433.2,186.6 428.2,177.7" id="path26" fill="#EA4334"></path>
					<path d="M370.3,307.2 L149.7,307.2 L83.5,192 L39.5,268.4 L97.5,369.4 C102.7,378.3 112.2,383.9 122.4,383.9 L309.5,383.9 C319.8,383.9 329.4,378.4 334.4,369.4 L370.3,307.2 L370.3,307.2 Z" id="path30" fill="#0074BC"></path>
					<path d="M282.2,307.2 L149.6,307.2 L83.5,192 L149.7,76.8 L282.2,76.8 L348.6,192 L282.2,307.2 L282.2,307.2 Z M216,134.5 C184.1,134.5 158.4,160.2 158.4,192.1 C158.4,224 184.1,249.7 216,249.7 C247.9,249.7 273.6,224 273.6,192.1 C273.6,160.2 247.8,134.5 216,134.5 L216,134.5 Z" id="Shape" fill="#E2E3E4"></path>
				</g>
			</g>
		</svg>
	);
};

export default GcpSVG;